<template>
  <v-app>
    <Navigation v-if="isLoggedIn" />
    <v-main>
      <router-view />
    </v-main>
    <Footer v-if="isLoggedIn" />
  </v-app>
</template>

<script>
import Navigation from "./components/Navigation.vue";
import Footer from "./components/Footer.vue";

import { mapState } from "pinia";
import { userStore } from "./stores/user";

export default {
  name: "App",

  data: () => ({
    //
  }),
  computed: {
    ...mapState(userStore, ["isLoggedIn"]),
  },
  components: {
    Navigation,
    Footer,
  },
};
</script>

<style scoped>
/* @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap'); */
#app {
  background-color: aliceblue;
}
#outer {
  background-color: aliceblue;
  height: 100%;
}
</style>
