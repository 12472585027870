<template>
  <div>
    <!-- mobile drawer -->
    <v-navigation-drawer
      app
      v-model="drawer"
      color="primary"
      dark
      v-if="isMobile"
    >
      <v-list>
        <v-list-item>
          <v-list-item-title
            class="text-wrap text-center text-uppercase font-weight-bold"
          >
            {{ user.name }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
      <v-list class="text-capitalize" subheader>
        <v-list-item :to="{ name: 'Dashboard' }">
          <v-list-item-icon>
            <v-icon>mdi-circle-small</v-icon>
          </v-list-item-icon>
          <v-list-item-title> dashboard </v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'LiveSession' }">
          <v-list-item-icon>
            <v-icon>mdi-circle-small</v-icon>
          </v-list-item-icon>
          <v-list-item-title> Live Session </v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'Calendar' }">
          <v-list-item-icon>
            <v-icon>mdi-circle-small</v-icon>
          </v-list-item-icon>
          <v-list-item-title> calendar & agendas </v-list-item-title>
        </v-list-item>
        <v-list-group
          prepend-icon="mdi-circle-small"
          active-class="white--text"
          v-for="item in navItems"
          :key="item.title"
        >
          <template v-slot:activator>
            <v-list-item-title class="text-wrap">{{
              item.title
            }}</v-list-item-title>
          </template>
          <v-list>
            <v-list-item
              v-for="child in item.children"
              :key="child.title"
              :to="{ name: child.component }"
            >
              <v-list-item-title>
                {{ child.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-list-group>

        <v-subheader class="text-uppercase mx-auto" v-if="isAdmin | isAssistant"
          >Admin</v-subheader
        >

        <v-list-item
          :to="{ name: 'MembersManagement' }"
          v-if="isAdmin | isAssistant"
        >
          <v-list-item-icon>
            <v-icon> mdi-circle-small </v-icon>
          </v-list-item-icon>
          <v-list-item-title> members </v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'CommiteeManagement' }">
          <v-list-item-icon>
            <v-icon> mdi-circle-small </v-icon>
          </v-list-item-icon>
          <v-list-item-title> commitees </v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'SessionManagement' }">
          <v-list-item-icon>
            <v-icon> mdi-circle-small </v-icon>
          </v-list-item-icon>
          <v-list-item-title> sessions </v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'MotionBillActManagement' }">
          <v-list-item-icon>
            <v-icon> mdi-circle-small </v-icon>
          </v-list-item-icon>
          <v-list-item-title> track motions, bills and acts </v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'ClaimsManagement' }">
          <v-list-item-icon>
            <v-icon> mdi-circle-small </v-icon>
          </v-list-item-icon>
          <v-list-item-title> member claims </v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'LoansManagement' }">
          <v-list-item-icon>
            <v-icon> mdi-circle-small </v-icon>
          </v-list-item-icon>
          <v-list-item-title> member loans </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="isAdmin | isAssistant"
          :to="{ name: 'VotingManagement' }"
          @click="(mini = true), (selected = null)"
        >
          <v-list-item-icon>
            <v-icon>mdi-book</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-wrap"
            >Voting Management</v-list-item-title
          >
        </v-list-item>
        <v-list-item
          v-if="isAdmin | isAssistant"
          :to="{ name: 'RoomsManagement' }"
          @click="(mini = true), (selected = null)"
        >
          <v-list-item-icon>
            <v-icon>mdi-message-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-wrap"
            >Rooms Management</v-list-item-title
          >
        </v-list-item>
        <v-list-item
          v-if="isAdmin | isAssistant"
          :to="{ name: 'DocumentUpload' }"
          @click="(mini = true), (selected = null)"
        >
          <v-list-item-icon>
            <v-icon>mdi-book</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-wrap"
            >Other Documents</v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- drawer for desktop -->
    <v-navigation-drawer
      app
      left
      v-if="!isMobile"
      :mini-variant.sync="mini"
      mini-variant-width="83"
      mobile-breakpoint="xs"
      color="primary"
      fixed
      floating
      dark
      height="100%"
    >
      <v-list two-line>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title
              class="text-no-wrap text-justify font-weight-bold"
            >
              <div :class="!mini ? 'text-center' : 'text-caption'">
                {{ time }}
              </div>
            </v-list-item-title>
            <v-list-item-subtitle class="text- text-justify font-weight-bold">
              <div :class="!mini ? 'text-center' : 'text-caption'">
                {{ date }}
              </div>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list class="text-capitalize" subheader>
        <v-list-item
          :to="{ name: 'Dashboard' }"
          @click="(mini = true), (selected = null)"
        >
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title> dashboard </v-list-item-title>
        </v-list-item>
        <v-list-item
          :to="{ name: 'LiveSession' }"
          @click="(mini = true), (selected = null)"
        >
          <v-list-item-icon>
            <v-icon>mdi-book-play</v-icon>
          </v-list-item-icon>
          <v-list-item-title> live session </v-list-item-title>
        </v-list-item>
        <v-list-item
          :to="{ name: 'Calendar' }"
          @click="(mini = true), (selected = null)"
        >
          <v-list-item-icon>
            <v-icon>mdi-calendar</v-icon>
          </v-list-item-icon>
          <v-list-item-title> calendar & agendas </v-list-item-title>
        </v-list-item>
        <v-list-item
          :to="{ name: 'Directory' }"
          @click="(mini = true), (selected = null)"
        >
          <v-list-item-icon>
            <v-icon>mdi-collage</v-icon>
          </v-list-item-icon>
          <v-list-item-title> Directory </v-list-item-title>
        </v-list-item>
        <v-list-group
          prepend-icon="mdi-account-group"
          active-class="white--text"
          :value="!mini && selected === 'committee'"
        >
          <template v-slot:activator>
            <v-list-item-title class="text-wrap">Committee</v-list-item-title>
          </template>
          <v-list>
            <v-list-item
              :to="{ name: 'YourCommittee' }"
              @click="(mini = true), (selected = 'committee')"
              v-if="user.role.name == 'member'"
            >
              <v-list-item-title> Your Committee </v-list-item-title>
            </v-list-item>
            <v-list-item
              :to="{ name: 'CommiteeReports' }"
              @click="(mini = true), (selected = 'committee')"
            >
              <v-list-item-title> Committee Reports </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-list-group>
        <v-list-group
          :prepend-icon="item.icon"
          active-class="white--text"
          v-for="item in navItems"
          :key="item.title"
          @click="selected = item"
          :value="!mini && selected === item"
        >
          <template v-slot:activator>
            <v-list-item-title class="text-wrap">{{
              item.title
            }}</v-list-item-title>
          </template>
          <v-list>
            <v-list-item
              v-for="child in item.children"
              :key="child.title"
              :to="{ name: child.component }"
              @click="mini = true"
            >
              <v-list-item-title>
                {{ child.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-list-group>

        <v-list-item
          v-if="isSpeaker"
          :to="{ name: 'VotingManagement' }"
          @click="(mini = true), (selected = null)"
        >
          <v-list-item-icon>
            <v-icon>mdi-vote</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-wrap"
            >Voting Management</v-list-item-title
          >
        </v-list-item>
        <!-- <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-title> settings </v-list-item-title>
        </v-list-item> -->

        <v-divider></v-divider>

        <v-subheader
          ><p class="mx-auto text-uppercase" v-if="isAdmin | isAssistant">
            admin
          </p></v-subheader
        >

        <v-list-item
          v-if="isAdmin | isAssistant"
          :to="{ name: 'MembersManagement' }"
          @click="(mini = true), (selected = null)"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-title>members </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="isAdmin | isAssistant"
          :to="{ name: 'CommiteeManagement' }"
          @click="(mini = true), (selected = null)"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-multiple-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-title>commitees </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="isAdmin | isAssistant"
          :to="{ name: 'SessionManagement' }"
          @click="(mini = true), (selected = null)"
        >
          <v-list-item-icon>
            <v-icon>mdi-book-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-title>sessions</v-list-item-title>
        </v-list-item>

        <v-list-group
          active-class="white--text"
          prepend-icon="mdi-book-refresh"
          v-if="isAdmin | isAssistant"
        >
          <template v-slot:activator>
            <v-list-item-title class="text-wrap">
              track motions, bills and acts
            </v-list-item-title>
          </template>

          <v-list>
            <v-list-item :to="{ name: 'Motions' }">
              <v-list-item-icon>
                <v-icon> mdi-circle-small </v-icon>
              </v-list-item-icon>
              <v-list-item-title> Motions </v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'Enacted' }">
              <v-list-item-icon>
                <v-icon> mdi-circle-small </v-icon>
              </v-list-item-icon>
              <v-list-item-title> Motions Enacted </v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'Bills' }">
              <v-list-item-icon>
                <v-icon> mdi-circle-small </v-icon>
              </v-list-item-icon>
              <v-list-item-title> Bills </v-list-item-title>
            </v-list-item>
            <!-- <v-list-item :to="{ name: 'Acts' }">
              <v-list-item-icon>
                <v-icon> mdi-circle-small </v-icon>
              </v-list-item-icon>
              <v-list-item-title> Acts </v-list-item-title>
            </v-list-item> -->
          </v-list>
        </v-list-group>

        <v-list-group
          active-class="white--text"
          prepend-icon="mdi-finance"
          v-if="isAdmin"
        >
          <template v-slot:activator>
            <v-list-item-title class="text-wrap">
              member loans
            </v-list-item-title>
          </template>

          <v-list>
            <v-list-item :to="{ name: 'PendingLoans' }">
              <v-list-item-icon>
                <v-icon> mdi-circle-small </v-icon>
              </v-list-item-icon>
              <v-list-item-title> pending loans </v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'ApprovedLoans' }">
              <v-list-item-icon>
                <v-icon> mdi-circle-small </v-icon>
              </v-list-item-icon>
              <v-list-item-title> approved loans </v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'DeniedLoans' }">
              <v-list-item-icon>
                <v-icon> mdi-circle-small </v-icon>
              </v-list-item-icon>
              <v-list-item-title> denied loans </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-list-group>

        <v-list-group
          active-class="white--text"
          prepend-icon="mdi-cash-multiple"
          v-if="isAdmin"
        >
          <template v-slot:activator>
            <v-list-item-title class="text-wrap">
              member claims
            </v-list-item-title>
          </template>

          <v-list>
            <v-list-item :to="{ name: 'PendingClaims' }">
              <v-list-item-icon>
                <v-icon> mdi-circle-small </v-icon>
              </v-list-item-icon>
              <v-list-item-title> pending claims </v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'ApprovedClaims' }">
              <v-list-item-icon>
                <v-icon> mdi-circle-small </v-icon>
              </v-list-item-icon>
              <v-list-item-title> approved claims </v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'DeniedClaims' }">
              <v-list-item-icon>
                <v-icon> mdi-circle-small </v-icon>
              </v-list-item-icon>
              <v-list-item-title> denied claims </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-list-group>

        <v-list-item
          v-if="isAdmin | isAssistant"
          :to="{ name: 'VotingManagement' }"
          @click="(mini = true), (selected = null)"
        >
          <v-list-item-icon>
            <v-icon>mdi-vote</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-wrap"
            >Voting Management</v-list-item-title
          >
        </v-list-item>

        <v-list-item
          v-if="isAdmin | isAssistant"
          :to="{ name: 'RoomsManagement' }"
          @click="(mini = true), (selected = null)"
        >
          <v-list-item-icon>
            <v-icon>mdi-message-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-wrap"
            >Rooms Management</v-list-item-title
          >
        </v-list-item>

        <v-list-item
          v-if="isAdmin | isAssistant"
          :to="{ name: 'DocumentUpload' }"
          @click="(mini = true), (selected = null)"
        >
          <v-list-item-icon>
            <v-icon>mdi-book</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-wrap"
            >Other Documents</v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="transparent" absolute flat>
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
        v-if="isMobile"
      ></v-app-bar-nav-icon>

      <v-app-bar-nav-icon
        @click="mini = !mini"
        v-if="$vuetify.breakpoint.md"
      ></v-app-bar-nav-icon>

      <v-list-item>
        <v-list-item-icon>
          <v-icon> mdi-gavel </v-icon>
        </v-list-item-icon>
        <v-list-item-title class="text-uppercase">
          <div v-if="isMobile">lims</div>
          <div v-if="!isMobile">legistlative information management system</div>
        </v-list-item-title>
      </v-list-item>

      <v-spacer></v-spacer>

      <div class="text-no-wrap my-auto mr-2" v-if="!isMobile">
        Hello {{ user.name }}
      </div>

      <v-menu offset-y open-on-hover>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-on="on" v-bind="attrs">
            <v-badge bordered color="error" class="mr-2" overlap dot>
              <v-icon>mdi-bell</v-icon>
            </v-badge>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-icon>
                <v-icon> mdi-emoticon-outline </v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                There are no notifications
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>

      <v-menu offset-y transition="scale-transition" class="mr-2" open-on-hover>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-on="on" v-bind="attrs">
            <v-icon large> mdi-account-circle </v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-list class="text-capitalize">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-title> account </v-list-item-title>
            </v-list-item>
            <v-list-item @click="logoutUser">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title> logout </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-dialog v-model="chatDialog" width="auto">
      <Chat :currentUser="user" @close="closeChat" />
    </v-dialog>

    <v-btn color="primary" fab bottom right fixed @click="chatDialog = true">
      <v-icon>mdi-message</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { userStore } from "../stores/user.js";
import Chat from "@/components/Chat.vue";

export default {
  data() {
    return {
      selected: null,
      navItems: [
        {
          title: "house business",
          component: "HouseBusiness",
          icon: "mdi-gavel",
          children: [
            {
              title: "house sessions",
              component: "HouseSessions",
            },
            {
              title: "order papers",
              component: "OrderPapers",
            },
            {
              title: "motions introduced",
              component: "MotionsIntroduced",
            },
            {
              title: "motions enacted",
              component: "MotionsEnacted",
            },
            {
              title: "bills adopted",
              component: "BillsAdopted",
            },
            {
              title: "acts adopted",
              component: "ActsAdopted",
            },
            {
              title: "public hearings",
              component: "PublicHearings",
            },
            {
              title: "votes and proceedings",
              component: "VotingRecord",
            },
            {
              title: "meeting attendance",
              component: "MeetingAttendance",
            },
            {
              title: "hansard records",
              component: "Hansard",
            },
            {
              title: "papers laid",
              component: "PapersLaid",
            },
            {
              title: "petitions",
              component: "Petitions",
            },
            {
              title: "standing order",
              component: "StandingOrder",
            },
            {
              title: "statements",
              component: "Statements",
            },
            // {
            //   title: "meeting statistics",
            //   component: "MeetingStatistics",
            // },
          ],
        },
        {
          title: "boards and commisions",
          icon: "mdi-human-male-board-poll",
          component: "BoardsCommisions",
          children: [
            // {
            //   title: "approvals",
            //   component: "Approvals",
            // },
            {
              title: "evaluation reports",
              component: "EvaluationReports",
            },
            {
              title: "budget documents",
              component: "BudgetDocuments",
            },
          ],
        },
        {
          title: "loans and claims",
          icon: "mdi-cash-multiple",
          component: "LoansClaims",
          children: [
            {
              title: "expense claims",
              component: "Claims",
            },
            {
              title: "loans",
              component: "Loans",
            },
            {
              title: "payslips",
              component: "PaySlips",
            },
          ],
        },
      ],
      drawer: false,
      mini: true,
      date: null,
      time: null,
      interval: undefined,
      chatDialog: false,
    };
  },
  components: { Chat },

  computed: {
    ...mapState(userStore, ["user"]),
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    isAdmin() {
      return this.user.role.name === "Admin" || this.user.role.name === "Clerk";
    },
    isAssistant() {
      return this.user.role.name === "Clerk-Assistant";
    },
    isSpeaker() {
      return this.user.role.name === "Speaker";
    },
  },
  methods: {
    ...mapActions(userStore, ["logout"]),
    logoutUser() {
      this.logout()
        .then(() => {
          userStore().$reset();
          this.$router.replace({ name: "Login" });
        })
        .catch(() => {});
    },
    showTime() {
      setInterval(() => {
        var week = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
        var cd = new Date();
        this.time =
          this.zeroPadding(cd.getHours(), 2) +
          ":" +
          this.zeroPadding(cd.getMinutes(), 2) +
          ":" +
          this.zeroPadding(cd.getSeconds(), 2);
        this.date = this.zeroPadding(cd.getDate(), 2) + " " + week[cd.getDay()];
      }, 1000);
    },
    zeroPadding(num, digit) {
      var zero = "";
      for (var i = 0; i < digit; i++) {
        zero += "0";
      }
      return (zero + num).slice(-digit);
    },
    closeChat() {
      this.chatDialog = false;
    },
  },
  mounted() {
    this.showTime();
  },
};
</script>

<style></style>
