import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Nprogess from "nprogress";
import "nprogress/nprogress.css";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/session",
    name: "LiveSession",
    component: () => import("../views/LiveSession.vue"),
  },
  {
    path: "/members-management",
    name: "MembersManagement",
    component: () => import("../views/MembersManagement.vue"),
  },
  {
    path: "/commitees-management",
    name: "CommiteeManagement",
    component: () => import("../views/CommiteeManagement.vue"),
  },
  {
    path: "/motions-management",
    name: "MotionBillActManagement",
    component: () => import("../views/MotionBillActManagement.vue"),
    children: [
      {
        path: "motions",
        name: "Motions",
        component: () =>
          import("@/components/MotionBillActManagement/Motions.vue"),
      },
      {
        path: "motions-enacted",
        name: "Enacted",
        component: () =>
          import("@/components/MotionBillActManagement/MotionsEnacted.vue"),
      },
      {
        path: "bills",
        name: "Bills",
        component: () =>
          import("@/components/MotionBillActManagement/Bills.vue"),
      },
      {
        path: "acts",
        name: "Acts",
        component: () =>
          import("@/components/MotionBillActManagement/Acts.vue"),
      },
    ],
  },
  {
    path: "/session-management",
    name: "SessionManagement",
    component: () => import("../views/SessionManagement.vue"),
  },
  {
    path: "/voting-management",
    name: "VotingManagement",
    component: () => import("../views/VotingItems.vue"),
  },
  {
    path: "/rooms-management",
    name: "RoomsManagement",
    component: () => import("../views/ChatGroupManagement.vue"),
  },
  {
    path: "/claims-management",
    name: "ClaimsManagement",
    component: () => import("../views/ClaimsManagement.vue"),
    children: [
      {
        path: "pending-claims",
        name: "PendingClaims",
        component: () => import("../components/ClaimsManagement/Pending.vue"),
      },
      {
        path: "approved-claims",
        name: "ApprovedClaims",
        component: () => import("../components/ClaimsManagement/Approved.vue"),
      },
      {
        path: "denied-claims",
        name: "DeniedClaims",
        component: () => import("../components/ClaimsManagement/Denied.vue"),
      },
    ],
  },
  {
    path: "/loans-management",
    name: "LoansManagement",
    component: () => import("../views/LoansManagement.vue"),
    children: [
      {
        path: "pending-loans",
        name: "PendingLoans",
        component: () =>
          import("../components/LoansManagement/PendingLoans.vue"),
      },
      {
        path: "approved-loans",
        name: "ApprovedLoans",
        component: () =>
          import("../components/LoansManagement/ApprovedLoans.vue"),
      },
      {
        path: "denied-loans",
        name: "DeniedLoans",
        component: () =>
          import("../components/LoansManagement/DeniedLoans.vue"),
      },
    ],
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("../views/Dashboard.vue"),
  },
  {
    path: "/calendar",
    name: "Calendar",
    component: () => import("../views/Calendar.vue"),
  },
  {
    path: "/directory",
    name: "Directory",
    component: () => import("../views/Directory.vue"),
  },
  {
    path: "/committee",
    name: "Committee",
    component: () => import("../views/Committee.vue"),
    children: [
      {
        path: "your-committee",
        name: "YourCommittee",
        component: () => import("../components/Committee/YourCommittee.vue"),
      },
      {
        path: "commitee-reports",
        name: "CommiteeReports",
        component: () => import("../components/Committee/CommiteeReports.vue"),
      },
    ],
  },
  {
    path: "/house-proceedings",
    name: "HouseBusiness",
    component: () => import("../views/HouseBusiness.vue"),
    children: [
      {
        path: "proceedings",
        name: "HouseSessions",
        component: () =>
          import("../components/HouseBusiness/HouseSessions.vue"),
      },
      {
        path: "motions-introduced",
        name: "MotionsIntroduced",
        component: () =>
          import("../components/HouseBusiness/MotionsIntroduced.vue"),
      },
      {
        path: "motions-enacted",
        name: "MotionsEnacted",
        component: () =>
          import("../components/HouseBusiness/MotionsEnacted.vue"),
      },
      {
        path: "bills-adopted",
        name: "BillsAdopted",
        component: () => import("../components/HouseBusiness/BillsAdopted.vue"),
      },
      {
        path: "actions-adopted",
        name: "ActsAdopted",
        component: () => import("../components/HouseBusiness/ActsAdopted.vue"),
      },
      {
        path: "public-hearings",
        name: "PublicHearings",
        component: () =>
          import("../components/HouseBusiness/PublicHearings.vue"),
      },
      {
        path: "voting-record",
        name: "VotingRecord",
        component: () => import("../components/HouseBusiness/VotingRecord.vue"),
      },
      {
        path: "hansard",
        name: "Hansard",
        component: () => import("../components/HouseBusiness/Hansard.vue"),
      },
      {
        path: "meeting-attendance",
        name: "MeetingAttendance",
        component: () =>
          import("../components/HouseBusiness/MeetingAttendance.vue"),
      },
      {
        path: "meeting-stats",
        name: "MeetingStatistics",
        component: () =>
          import("../components/HouseBusiness/MeetingStatistics.vue"),
      },
      {
        path: "order-papers",
        name: "OrderPapers",
        component: () => import("../components/HouseBusiness/OrderPapers.vue"),
      },
      {
        path: "papers-laid",
        name: "PapersLaid",
        component: () => import("../components/HouseBusiness/PapersLaid.vue"),
      },
      {
        path: "petitions",
        name: "Petitions",
        component: () => import("../components/HouseBusiness/Petitions.vue"),
      },
      {
        path: "statements",
        name: "Statements",
        component: () => import("../components/HouseBusiness/Statements.vue"),
      },
      {
        path: "standing-order",
        name: "StandingOrder",
        component: () =>
          import("../components/HouseBusiness/StandingOrder.vue"),
      },
    ],
  },
  // {
  //   path: "/file:no",
  //   name: "File",
  //   component: () => import("../components/LegistlativeFile.vue"),
  // },
  {
    path: "/boards-and-commisions",
    name: "BoardsCommisions",
    component: () => import("../views/BoardsCommisions.vue"),
    children: [
      {
        path: "approvals",
        name: "Approvals",
        component: () => import("../components/BoardsCommisions/Approvals.vue"),
      },
      {
        path: "evaluation-reports",
        name: "EvaluationReports",
        component: () =>
          import("../components/BoardsCommisions/EvaluationReports.vue"),
      },
      {
        path: "budget-documents",
        name: "BudgetDocuments",
        component: () =>
          import("../components/BoardsCommisions/BudgetDocuments.vue"),
      },
    ],
  },
  {
    path: "/loans-and-claims",
    name: "LoansClaims",
    component: () => import("../views/LoansClaims.vue"),
    children: [
      {
        path: "claims",
        name: "Claims",
        component: () => import("../components/LoansClaims/ExpenseClaims.vue"),
      },
      {
        path: "loans",
        name: "Loans",
        component: () => import("../components/LoansClaims/Loans.vue"),
      },
      {
        path: "payslips",
        name: "PaySlips",
        component: () => import("../components/LoansClaims/Payslips.vue"),
      },
    ],
  },
  {
    path: "/other-documents",
    name: "DocumentUpload",
    component: () => import("../views/DocumentUploads.vue"),
  },
  {
    path: "*",
    component: () => import("../views/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  Nprogess.start();
  // if (to.name === "Login") {
  //   next();
  // } else
  if (to.name !== "Login" && !localStorage.getItem("token")) {
    next({ name: "Login" });
  } else next();
});

router.afterEach(() => {
  Nprogess.done();
});
export default router;
