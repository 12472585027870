// all APIs
import axios from "axios";

const apiClient = new axios.create({
  baseURL: "https://eparliamentapi.suvence.com/api/",
  credentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

const fileClient = new axios.create({
  baseURL: "https://eparliamentapi.suvence.com/api/",
  credentials: true,
  headers: {
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*",
  },
});

const chatClient = new axios.create({
  baseURL: "https://api.talkjs.com/",
  credentials: true,
  headers: {
    Authorization: `Bearer sk_test_PPywBg42i18j59BOcAddNAGlZZeDw7LX
    `,
    "Content-Type": "application/json",
  },
});

// interceptors for normal client
apiClient.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("token");

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// interceptors for file client
fileClient.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("token");

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default {
  // get method for any other use
  get(url) {
    return apiClient.get(`${url}`);
  },
  // authentication
  login(data) {
    return apiClient.post("login", data);
  },
  logout() {
    return apiClient.post("logout");
  },
  // member creation
  createUser(data) {
    return apiClient.post("user", data);
  },
  getUsers() {
    return apiClient.get("user");
  },
  updateUser(id, data) {
    return apiClient.put(`user/${id}`, data);
  },
  deleteUser(id) {
    return apiClient.delete(`user/${id}`);
  },
  //   upload files
  uploadFile(data) {
    return fileClient.post("files", data);
  },
  getSpecificFileType(name) {
    return apiClient.post("files-type", { type: name });
  },
  getFiles(type) {
    return apiClient.get(`files/${type}`);
  },
  getFileCount() {
    return apiClient.post(`files-type-count`);
  },
  getFile(id) {
    return apiClient.get(`files/${id}`);
  },
  updateFile(id) {
    return fileClient.post(`file/${id}`);
  },
  deleteFile(id) {
    return apiClient.delete(`files/${id}`);
  },
  // commitees
  createCommitee(data) {
    return apiClient.post("committees", data);
  },
  addCommiteeMembers(data) {
    return apiClient.post(`user/committees`, data);
  },
  getCommitees() {
    return apiClient.get("committees");
  },
  editCommitee(id, data) {
    return apiClient.put(`committees/${id}`, data);
  },
  deleteCommitee(id) {
    return apiClient.delete(`committees/${id}`);
  },
  // sessions
  createSession(data) {
    return apiClient.post("housesessions", data);
  },
  getSessions() {
    return apiClient.get("housesessions");
  },
  editSession(id, data) {
    return apiClient.put(`housesessions/${id}`, data);
  },
  deleteSession(id) {
    return apiClient.delete(`housesessions/${id}`);
  },
  // motions
  createMotion(data) {
    return fileClient.post("motions", data);
  },
  getMotions() {
    return apiClient.get("motions");
  },
  editMotion(id, data) {
    return fileClient.put(`motion-update/${id}`, data);
  },
  deleteMotion(id) {
    return apiClient.delete(`motions/${id}`);
  },
  // motions enacted
  createMotionEnacted(data) {
    return apiClient.post("motionsenacted", data);
  },
  getMotionsEnacted() {
    return apiClient.get("motionsenacted");
  },
  editMotionEnacted(id, data) {
    return fileClient.put(`motionsenacted/${id}`, data);
  },
  // bills
  createBill(data) {
    return fileClient.post("bills", data);
  },
  getBills() {
    return apiClient.get("bills");
  },
  editBill(id, data) {
    return fileClient.put(`bills/${id}`, data);
  },
  // acts
  createAct(data) {
    return fileClient.post("acts", data);
  },
  getActs() {
    return apiClient.get("acts");
  },
  editAct(id, data) {
    return fileClient.put(`acts/${id}`, data);
  },
  // voting
  createVote(data) {
    return apiClient.post("/votes", data);
  },
  getVotes() {
    return apiClient.get("/votes");
  },
  getVoteItems() {
    return apiClient.get("voting_items");
  },
  createVoteItem(data) {
    return apiClient.post("voting_items", data);
  },
  editVoteItem(id, data) {
    return apiClient.put(`voting_items/${id}`, data);
  },
  deleteVoteItem(id) {
    return apiClient.delete(`voting_items/${id}`);
  },
  // claims
  createClaim(data) {
    return apiClient.post("claims", data);
  },
  getClaims() {
    return apiClient.get("claims");
  },
  getMemberClaims(id) {
    return apiClient.get(`claim-member/${id}`);
  },
  editClaim(id, data) {
    return apiClient.put(`claims/${id}`, data);
  },
  deleteClaim(id) {
    return apiClient.delete(`claims/${id}`);
  },
  //loans
  createLoan(data) {
    return apiClient.post("loans", data);
  },
  getLoans() {
    return apiClient.get("loans");
  },
  getMemberLoans(id) {
    return apiClient.get(`loan-member/${id}`);
  },
  editLoan(id, data) {
    return apiClient.put(`loans/${id}`, data);
  },
  deleteLoan(id) {
    return apiClient.delete(`loans/${id}`);
  },
  // meetings
  createMeeting(data) {
    return apiClient.post("onlinemeetings", data);
  },
  getMeetings() {
    return apiClient.get("onlinemeetings");
  },
  // talk js
  createTalkUser(userId, data) {
    return chatClient.put(`v1/t1DlROik/users/${userId}`, data);
  },
  createRoom(id, data) {
    return chatClient.put(`v1/t1DlROik/conversations/${id}`, data);
  },
  getRooms() {
    return chatClient.get(`v1/t1DlROik/conversations/`);
  },
  deleteRoom(id) {
    return chatClient.delete(`v1/t1DlROik/conversations/${id}`);
  },
  sendAddedAlert(conversationId) {
    return chatClient.post(
      `v1/t1DlROik/conversations/${conversationId}/messages`,
      [{ text: "You have been added", type: "SystemMessage" }]
    );
  },
  addUserToRoom(userId, conversationId) {
    return chatClient.put(
      `v1/t1DlROik/conversations/${conversationId}/participants/${userId}`
    );
  },
  removeUserFromRoom(userId, conversationId) {
    return chatClient.delete(
      `v1/t1DlROik/conversations/${conversationId}/participants/${userId}`
    );
  },
};
