import { defineStore } from "pinia";
import Api from "../services/Api";

export const memberStore = defineStore("members", {
  state: () => {
    return {
      members: [],
      member: null,
    };
  },
  getters: {
    memberNames: (state) => {
      let names = [];
      for (let member of state.members) {
        names.push(member.name);
      }
      return names;
    },
  },
  actions: {
    getMembers() {
      return Api.getUsers()
        .then((response) => {
          this.members = response.data.data;
        })
        .catch((error) => {
          throw error;
        });
    },
    createMember(data) {
      return Api.createUser(data)
        .then((response) => {
          this.getMembers();
          this.member = response.data.data;
          return response;
        })
        .catch((error) => {
          throw error;
        });
    },
    updateMember(id, data) {
      return Api.updateUser(id, data)
        .then(() => {
          this.getMembers();
        })
        .catch((error) => {
          throw error;
        });
    },
    deleteMember(id) {
      return Api.deleteUser(id)
        .then((res) => {
          this.getMembers();
          return res;
        })
        .catch((error) => {
          throw error;
        });
    },
  },
});
